import NavbarPage from "../NabarPage/navbarPage";
import React, {useEffect} from "react";
import FooterPage from "../FooterPage/footerPage";
import {Link} from "react-router-dom";

const CareersPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    {/*nav bar*/}
                    <div className="row justify-content-center fixed-top other-page-nav-color">
                        <NavbarPage/>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 py-4 my-3"}></div>
                    </div>

                    {/* hero section*/}

                    <div className={"row"}>
                        <div className={"col-12 py-2 my-md-5 my-4"}>
                            <div className={"row justify-content-center"}>
                                <div className={"col-md-6 col-12 d-flex flex-column text-center align-items-center"}>
                                    <label className={"font-size-56 font-weight-400p font-color-neutral-dark"}>Careers
                                        at N.B.</label>
                                    <div className={"text-center my-4"}>
                                        <label className={"font-size-18 font-weight-400p font-color-neutral-dark"}>Join
                                            our team at Ethanol company and be part of a dynamic workforce
                                            dedicated to innovation and sustainability. Grow your career with us
                                            today!</label>
                                    </div>
                                    <a href={"#job-details-container"}>
                                        <button type={"button"}
                                                className={"button-bg mt-2 font-size-18 font-color-neutral-dark"}>See open
                                            roles
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className={"row justify-content-center mt-md-5 mt-4"}>
                                <div className={"col-md-10 col-11 mt-2"}>
                                    <img className={"img-fluid bg-transparent w-100"}
                                         src={"/images/careers/careers-hero-section-image.png"}
                                         alt={"careers-hero-section-image"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* job opening */}

                    <div className={"row justify-content-center bg-color-light-green-color "} id={"job-details-container"}>
                        <div className={"col-md-10 col-11 py-md-5 py-4 my-2"}>
                            <div className={"row justify-content-between job-opening-container"}>
                                <div className={"col-md-3 col-12 mb-2 mb-md-0 sticky-container"}>
                                    <label className={"font-size-56 font-weight-400p font-color-neutral-dark"}>
                                        Job Opening
                                    </label>
                                    <div className={"my-md-4 my-3"}>
                                        <label
                                            className={"font-size-16 font-weight-400p font-color-neutral-grey-darker"}>
                                            Join our dynamic team at Ethanol company and discover rewarding
                                            opportunities. Check out our open roles by clicking the button
                                            below and please mention your interest of job role in the message box.
                                        </label>
                                    </div>
                                    <Link to={"/contact-us"}>
                                        <button type={"button"}
                                                className={"button-bg mt-md-2 mt-3 font-size-18 font-color-neutral-dark"}>Apply
                                            job
                                        </button>
                                    </Link>
                                </div>
                                <div className={"col-md-8 col-12 mt-4 mt-md-0 scrollable-container"}>
                                    <div className={"w-100 bg-white br-16px p-md-4 p-3"}>
                                        <div className={"p-2"}>
                                            {/*<label className={"font-size-24 font-weight-500p font-color-neutral-dark"}>*/}
                                            {/*    Departmental Overview:*/}
                                            {/*</label>*/}
                                            <div className={"d-flex flex-column gap-2 mt-3"}>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>1. Deputy Manager Power plant</span>
                                                    {/*Positions in R&D involve developing more efficient ethanol*/}
                                                    {/*production*/}
                                                    {/*processes, exploring new feedstocks, and improving overall ethanol*/}
                                                    {/*yield.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>2. Deputy Manager Process </span>
                                                    {/*Engineers are needed for designing and optimizing ethanol*/}
                                                    {/*production plants, as well as ensuring compliance with environmental*/}
                                                    {/*regulations.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>3. Sr. Chemist </span>
                                                    {/*Roles in operations include managing*/}
                                                    {/*day-to-day production activities, ensuring quality control, and*/}
                                                    {/*maintaining safety standards.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span
                                                        className={"font-weight-500p font-color-neutral-dark"}>4. Shift Chemist </span>
                                                    {/*Opportunities exist in promoting ethanol as a fuel*/}
                                                    {/*alternative, building partnerships with automotive industries, and*/}
                                                    {/*educating consumers about its benefits.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span
                                                        className={"font-weight-500p font-color-neutral-dark"}>5. Lab Chemist </span>
                                                    {/*Professionals in this field handle compliance with*/}
                                                    {/*government regulations and policies related to ethanol production*/}
                                                    {/*and*/}
                                                    {/*distribution.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>6. Milling Operator </span>
                                                    {/*Careers focusing on the environmental*/}
                                                    {/*impact of ethanol production, including sustainability assessments*/}
                                                    {/*and carbon footprint analysis.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span
                                                        className={"font-weight-500p font-color-neutral-dark"}>7. Fermentation Operator </span>
                                                    {/*Managing the logistics of sourcing feedstocks,*/}
                                                    {/*distributing ethanol, and optimizing transportation networks.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>8. Distillery Operator </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>9. Dryer Operator </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>10. Boiler Attendant </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>11. Turbine Attendant </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>12. Fitter </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>13. Electrical Engineer </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>14. Electrician </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>15. Pump Operator </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>16. Casual Labour (Grain Unloading, Bagasse Feeding, Fermentation & Distillation Cleaning, DDGS) </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>17. Manager Accounts </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>18. Sr. Accountant </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>19. Accounts Executive </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>20. Jr. Accountant </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>21. Excise & Legal Officer </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>22. Store Manager </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>23. Store Incharge </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>24. Purchase Manager </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>25. Computer Operator </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={"font-weight-500p font-color-neutral-dark"}>26. Peon </span>
                                                    {/*Roles in finance involve budgeting,*/}
                                                    {/*financial analysis, and investment decisions related to ethanol*/}
                                                    {/*projects.*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"w-100 bg-white mt-4 br-16px p-md-4 p-3"}>
                                        <div className={"p-2"}>
                                            <label className={"font-size-24 font-weight-500p font-color-neutral-dark"}>
                                                Skills Required:
                                            </label>
                                            <div className={"d-flex flex-column gap-2 mt-4"}>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span
                                                        className={"font-weight-500p font-color-neutral-dark"}>1. Technical Expertise: </span>
                                                    Depending on the role, knowledge of chemical engineering,
                                                    biochemistry, agronomy, or environmental science may be required.
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span
                                                        className={"font-weight-500p font-color-neutral-dark"}>2. Analytical Skills: </span>
                                                    Ability to analyze data, troubleshoot issues, and optimize
                                                    processes.
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span className={" font-weight-500p font-color-neutral-dark"}>3. Communication: </span>
                                                    Effective communication skills are crucial for roles involving
                                                    collaboration with various stakeholders, including scientists,
                                                    engineers, policymakers, and the public.
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span
                                                        className={"font-weight-500p font-color-neutral-dark"}>4. Problem-Solving: </span>
                                                    Ethanol production involves complex challenges that require
                                                    innovative solutions.
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span
                                                        className={"font-weight-500p font-color-neutral-dark"}>5. Environmental Awareness: </span>
                                                    Understanding the environmental impacts and sustainability
                                                    considerations of ethanol production.
                                                </div>
                                                <div
                                                    className={"font-weight-400p font-size-16 font-color-neutral-grey-darker"}>
                                                    <span
                                                        className={"font-weight-500p font-color-neutral-dark"}>6. Regulatory Knowledge: </span>
                                                    Awareness of local and international regulations governing biofuels.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FooterPage/>

                </div>
            </div>
        </div>
    )
}

export default CareersPage;
