import './App.css';
import LandingPage from "./components/LandingPage/landingPage";
import CareersPage from "./components/CareersPage/careersPage";
import 'bootstrap/dist/css/bootstrap.css'
import "./components/Styles/scss/main.scss"
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ProductsPage from "./components/ProductsPage/productsPage";
import ContactUs from "./components/ContactUs/contactUs";

function App() {
    return (
        <div className="">
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<LandingPage/>}/>
                    <Route path={"/careers"} element={<CareersPage/>}/>
                    <Route path={'/contact-us'} element={<ContactUs/>}/>
                    <Route path={"/products/:id"} element={<ProductsPage/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
