import NavbarPage from "../NabarPage/navbarPage";
import React, {useEffect, useState} from "react";
import FooterPage from "../FooterPage/footerPage";
import productsData from '../Models/eachProduct.json';
import {Link, useParams} from "react-router-dom";
import jsPDF from "jspdf";

const ProductsPage = () => {

    const params = useParams();
    const id = params.id;

    const [product, setProduct] = useState([]);

    const handleDownloadPdf = () => {
        const data = product.map(each => {
            return each.pdf_content
        });
        const jsonData = data[0];
        const doc = new jsPDF();

        // Convert JSON data to text and add to PDF
        let yPos = 10;
        doc.setFontSize(24);
        doc.text('Technical Specifications:', 10, yPos);
        yPos += 10;
        jsonData.forEach((item) => {
            // Replace special subscript characters with Unicode equivalents if necessary
            const bulletPointText = `• ${item.technical_heading}: ${item.technical_desc}`;
            doc.setFontSize(18);
            doc.text(bulletPointText, 10, yPos);
            yPos += 10; // Move to the next line
        });
        doc.save(`${product[0].product_name} TDS.pdf`)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        const data = productsData.find(each => each.product_slug === id);
        setProduct(data ? [data] : []);
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-12"}>

                    {/*nav bar*/}
                    <div className="row justify-content-center fixed-top other-page-nav-color">
                        <NavbarPage/>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 py-4 my-3"}></div>
                    </div>
                    {/* hero section */}
                    <div className={"row"}>
                        <div className={"col-12 py-2 my-md-5 my-4"}>
                            <div className={"row d-flex justify-content-center"}>
                                {product.map(eachProduct => (
                                    <div className={"col-md-8 col-12 d-flex flex-column align-items-center"}
                                         key={eachProduct.id}>
                                        <label
                                            className={"font-size-56 text-center font-weight-400p font-color-neutral-dark"}>
                                            {eachProduct.product_name}</label>
                                        <div className={"text-center mt-md-4 mt-3"}>
                                            <label className={"font-size-18 font-weight-400p font-color-neutral-dark"}>
                                                {eachProduct.product_description}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={"row justify-content-center mb-2 pb-4"}>
                        {product.map(eachProduct => (
                            <div className={"col-11 bg-color-light-green-color px-0 mb-md-5 pb-4 br-16px"}
                                 key={eachProduct.id}>
                                <img className={"img-fluid w-100 rounded-top-3"}
                                     src={eachProduct.product_hero_image}
                                     alt={"product-hero-image"}/>
                                <div
                                    className={"row d-flex align-items-stretch px-md-4 px-3 pt-md-5 pt-4 mt-2 pb-md-3 mb-4 pb-2 justify-content-between"}>
                                    <div className={"col-md-7 col-12"}>
                                        <div className={""}>
                                            <label
                                                className={"font-size-32 font-weight-400p font-color-neutral-dark"}>
                                                {eachProduct.product_name}
                                            </label>
                                            <div className={"mt-3"}>
                                                <label
                                                    className={"font-size-16 font-weight-400p font-color-neutral-grey-darker"}>
                                                    {eachProduct.product_content_description}
                                                </label>
                                            </div>
                                            <div className={"mt-3 w-100"}>
                                                <label
                                                    className={"font-size-24 font-weight-500p font-color-neutral-dark"}>Applications</label>
                                                <ul className={"applications-list mt-3 d-flex flex-column gap-3"}>
                                                    {eachProduct.applications.map((eachApplication, index) => (
                                                        <li className={""} key={index}>
                                                            <div className={"d-flex flex-row row"}>
                                                                <div
                                                                    className={"col-12 font-size-16 font-weight-400p font-color-neutral-dark"}>
                                                                    <span
                                                                        className={"font-weight-600p"}>{eachApplication.application_name}</span> : {eachApplication.application_description}
                                                                </div>
                                                                {/*<div className={"col-md-4 col-6"}>*/}
                                                                {/*    <label className={"font-size-16 font-weight-600p font-color-neutral-dark"}>{eachApplication.application_name} : </label>*/}
                                                                {/*</div>*/}
                                                                {/*<div className={"col-md-7 col-6 d-flex flex-column justify-content-start"}>*/}
                                                                {/*    <label className={"font-size-16 font-weight-400p font-color-neutral-grey-darker"}>{eachApplication.application_description}</label>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {eachProduct.pdf_content.length > 0 ? (<div className={""}>
                                                <button type={"button"} onClick={handleDownloadPdf}
                                                        className={"button-bg mt-4 font-size-18 font-color-neutral-dark"}>
                                                    Download TDS
                                                </button>
                                            </div>) : (<div></div>)}
                                        </div>
                                    </div>
                                    <div className={"col-md-5 col-12"}>
                                        <div className={"mt-4 pt-2 pt-md-0 mt-md-0 h-100"}>
                                            <img className={"img-fluid h-100 w-100"}
                                                 src={eachProduct.product_content_image}
                                                 alt={"product-image"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* product contact us */}
                    <div className={"row bg-dark-green"}>
                        <div className={"col-12"}>
                            <div className={"row d-flex flex-column align-items-center py-2 my-md-5 my-4"}>
                                <div className={"col-12 text-center"}>
                                    {product.map(each => (
                                        <label className={"font-size-56 font-weight-500p text-white"}>{each.contact_heading}</label>
                                    ))}
                                </div>
                                <div className={"col-md-6 col-12 mt-md-4 mt-2 d-flex justify-content-center text-center"}>
                                    {product.map(each => (
                                        <label className={"font-size-18 font-weight-500p text-white"}
                                               key={each.id}>{each.contact_description}</label>
                                    ))}
                                </div>
                                <div className={"w-100 d-flex justify-content-center mt-4"}>
                                    <Link to={'/contact-us'}>
                                        <button type={"button"}
                                                className={"button-bg font-size-18 font-color-neutral-dark"}>
                                            Contact us
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FooterPage/>

                </div>
            </div>
        </div>
    )
}

export default ProductsPage;
