import React, {useEffect, useState} from "react";
import NavbarPage from "../NabarPage/navbarPage";
import FooterPage from "../FooterPage/footerPage";
import {TailSpin} from "react-loader-spinner";

const ContactUs = () => {

    const [loadingButton, setLoadingButton] = useState(false);
    const [showMessage, setShowMessage] = useState('');

    const handleOnFormSubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('enquiry-form');
        const data = new FormData(form);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,
        })
            .then(() => {
                setLoadingButton(false);
                setShowMessage('success');
                form.reset();
            }).catch((error) => {
            setLoadingButton(false);
            setShowMessage('error');
            form.reset();
            console.log(error)
        })
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-white"}>
                <div className={"col-12"}>
                    <div className="row justify-content-center fixed-top other-page-nav-color">
                        <NavbarPage/>
                    </div>

                    <div className={"row py-3 mt-5"}>
                        <div className={"col-12"}></div>
                    </div>

                    {/* contact us*/}
                    <div className={"row justify-content-between"}>
                        <div className={"col-md-11 col-12"}>
                            <div className={"row"}>
                                <div className={"col-12 mt-md-5 py-3"}></div>
                            </div>
                            {/* form and content */}
                            <div className={"row d-flex justify-content-md-between justify-content-center"}>
                                <div className={"col-md-6 col-12 d-flex flex-column"}>
                                    <div className={"row d-flex justify-content-center"}>
                                        <div className={"col-12 mb-md-5 mb-4 pb-2 text-center"}>
                                            <label
                                                className={"font-color-secondary-default font-weight-500p font-size-56"}>Contact
                                                us</label>
                                        </div>
                                    </div>
                                    <div
                                        className={"row py-md-5 py-2 bg-color-light-green-color mt-md-0 d-none d-md-flex flex-column align-items-center flex-grow-1"}>
                                        <div className={"col-12 pt-md-3"}></div>
                                        <div className={"col-md-5 col-11"}>
                                            <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Visiting
                                                hours</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>Monday
                                                    - Friday</label>
                                                <div>
                                                    <label
                                                        className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>10:00
                                                        am - 11:00 pm</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 my-3 py-md-4"}></div>
                                        <div className={"col-md-5 col-11"}>
                                            <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Our
                                                Address</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>B-183,
                                                    Gujranwala Town, New Delhi 110009</label>
                                            </div>
                                        </div>
                                        <div className={"col-12 my-3 py-md-4"}></div>
                                        <div className={"col-md-5 col-11"}>
                                            <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Get
                                                in touch</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>
                                                    +91-82854 50869
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-5 col-11 mt-md-0  rounded-4 bg-form-shadow p-md-4 p-3"}>
                                    <div>
                                        <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Get
                                            in Touch With Us</label>
                                        <div>
                                            <label
                                                className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>
                                                Interested in learning more about our products or discussing potential
                                                collaborations? We’d
                                                love to hear from you. Reach out to us at our corporate office in Delhi
                                                or visit our distillery in
                                                Haryana.
                                            </label>
                                        </div>
                                    </div>
                                    <form className={"d-flex flex-column mt-4 pt-3"} method={"POST"}
                                          onSubmit={handleOnFormSubmit}
                                          id={'enquiry-form'}
                                          action={'https://script.google.com/macros/s/AKfycbwKSHeANlNSBKvxEZSipVAJxk0z1fLTZklUIrFew-v-2G6W7-VEPj5vp_Etgoaca8yqPQ/exec'}>
                                        <div className={"w-100"}>
                                            <label className={"font-size-18 font-weight-500p font-color-neutral-dark"}
                                                   htmlFor={'fullName'}>
                                                Name</label>
                                            <input name={"FullName"} required={true} placeholder={"Enter your name"}
                                                   type={"text"} id={'fullName'}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={"phoneNumber"}
                                                   className={"font-size-18 font-weight-500p font-color-neutral-dark"}>
                                                Mobile Number</label>
                                            <input name={"PhoneNumber"} required={true} id={'phoneNumber'}
                                                   type={"number"} placeholder={"Enter your mobile number"}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'email'}
                                                   className={"font-size-18 font-weight-500p font-color-neutral-dark"}>
                                                E-mail id<span className={"font-color-grey-light"}>(optional)</span></label>
                                            <input name={"Email"} id={'email'} type={"email"}
                                                   placeholder={"Enter your email"}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'selectedDepartment'}
                                                   className={"font-size-18 font-weight-500p font-color-neutral-dark"}>Select
                                                the department</label>
                                            <select name={"Department"} id={'selectedDepartment'} type={"select"}
                                                    className={"form-select"}>
                                                <option
                                                    className={"font-size-18 font-weight-500p font-color-neutral-dark"}
                                                    value={'Hr'}>Hr
                                                </option>
                                                <option
                                                    className={"font-size-18 font-weight-500p font-color-neutral-dark"}
                                                    value={'Purchase'}>Purchase
                                                </option>
                                                <option
                                                    className={"font-size-18 font-weight-500p font-color-neutral-dark"}
                                                    value={'Sales'}>Sales
                                                </option>
                                            </select>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'message'}
                                                   className={"font-size-18 font-weight-500p font-color-neutral-dark"}>
                                                Message</label>
                                            <textarea name={"Message"} id={'message'} rows={5}
                                                      placeholder={"Write your message"}
                                                      className={"form-control"}></textarea>
                                        </div>
                                        <div className={"mt-4 pt-3 d-md-flex d-none"}>
                                            <button type="submit" disabled={loadingButton}
                                                    className={"button-bg bg-btn-shadow button-contact-us font-color-neutral-dark font-size-18 font-weight-500p"}>
                                                {loadingButton ? <TailSpin width={20} height={20}
                                                                           color={'#1D1D1D'}/> : 'Send Message'}
                                            </button>
                                        </div>
                                        <div className={"mt-4 pt-3 d-md-none d-flex"}>
                                            <button type="submit" disabled={loadingButton}
                                                    className={"button-bg bg-btn-shadow button-contact-us w-100 font-color-neutral-dark font-size-18 font-weight-500p"}>
                                                {loadingButton ? <TailSpin width={20} height={20}
                                                                           color={'#1D1D1D'}/> : 'Send Message'}
                                            </button>
                                        </div>
                                        {showMessage === 'success' && (
                                            <div className={"mt-4 bg-light-green p-md-4 p-3 br-8px"}>
                                                <label
                                                    className={"font-color-dark-green font-size-18 font-weight-500p"}>Thank
                                                    you for contacting us! Your message has been successfully
                                                    received. We will get back to you shortly.</label>
                                            </div>)}
                                        {showMessage === 'error' && (
                                            <div className={"mt-4 bg-light-red p-md-4 p-3 br-8px"}>
                                                <label
                                                    className={"font-color-neutral-dark font-size-18 font-weight-500p"}>Oops!
                                                    Something went wrong while submitting your message. Please try
                                                    again later. Thank you for your understanding.</label>
                                            </div>)}
                                    </form>
                                </div>

                            </div>
                            <div
                                className={"row py-md-5 py-4 bg-color-light-green-color mt-4 mt-md-0 d-md-none d-flex flex-column align-items-center flex-grow-1"}>
                                <div className={"col-12 pt-md-3"}></div>
                                <div className={"col-md-5 col-11 p-0"}>
                                    <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Visiting
                                        hours</label>
                                    <div>
                                        <label
                                            className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>Monday
                                            - Friday</label>
                                        <div>
                                            <label
                                                className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>10:00
                                                am - 11:00 pm</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12 my-3 py-md-4"}></div>
                                <div className={"col-md-5 col-11 p-0"}>
                                    <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Our
                                        Address</label>
                                    <div>
                                        <label
                                            className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>B-183,
                                            Gujranwala Town, New Delhi 110009</label>
                                    </div>
                                </div>
                                <div className={"col-12 my-3 py-md-4"}></div>
                                <div className={"col-md-5 col-11 p-0"}>
                                    <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Get
                                        in touch</label>
                                    <div>
                                        <label
                                            className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>
                                            +91-82854 50869
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 mt-md-5 py-md-3"}></div>
                            </div>
                        </div>
                    </div>
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
