import React from "react";
import * as Icon from 'react-feather'
import {Link} from "react-router-dom";

const FooterPage = () => {
    return (
        <div className="row px-md-4 py-md-2 py-3 bg-footer-container justify-content-center">
            <div className="col-10 py-md-5 my-4 px-0">
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-8 col-md-4 p-0">
                        <Link to={"/"}>
                            <div className={"w-100 d-flex align-items-center"}>
                                <img className={"img-fluid"} src={"/images/landing/Nakshatra-horizontal-version.png"}
                                     alt={"logo"}/>
                                {/*<div className={""}>*/}
                                {/*    <img className={"img-fluid w-100"} src={"/images/landing/Nakshatra-logo-content.png"}*/}
                                {/*         alt={"logo"}/>*/}
                                {/*    /!*<label className={`font-size-24 font-color-lightest-yellow text-wrap text-center`}>Nakshatra Biofuels Pvt.Ltd.</label>*!/*/}
                                {/*</div>*/}
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-md-4 mt-4 mt-md-0">
                        <div className="d-flex flex-column">
                            <div className="d-flex gap-2">
                                <div>
                                    <Icon.MapPin className="font-color-primary-yellow" strokeWidth={2}/>
                                </div>
                                <div>
                                    <label className="font-size-14 font-weight-500p text-white">B-183, Gujranwala Town, New Delhi 110009</label>
                                </div>
                            </div>
                            <div className="d-flex gap-2 mt-md-5 mt-4">
                                <div>
                                    <Icon.Mail className="font-color-primary-yellow" strokeWidth={2}/>
                                </div>
                                <div>
                                    <a href="mailto:info@nakshatrabiofuels.com" className="font-size-14 font-color-theme-50 font-weight-500p text-white" style={{textDecorationLine: "none"}}>
                                        info@nakshatrabiofuels.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={"font-color-neutral-grey-darker my-4 py-md-2"} />
                <div className={"row justify-content-between align-items-center"}>
                    <div className="col-md-6 col-12 d-flex justify-content-start gap-5">
                        <div>
                            <Link to={"/"}>
                                <label className="font-size-18 text-white services-button font-weight-500p">About</label>
                            </Link>
                        </div>
                        <div>
                            <Link to={"/"}>
                                <label className="font-size-18 text-white services-button font-weight-500p">Support</label>
                            </Link>
                        </div>
                        <div>
                            <Link to={"/contact-us"}>
                                <label className="font-size-18 text-white services-button font-weight-500p">Contact us</label>
                            </Link>
                        </div>
                    </div>
                    <div className={"col-md-6 col-12 mt-md-0 mt-4"}>
                        <div className={"row d-flex justify-content-end"}>
                            <div className={"col-md-6 col-12"}>
                                <label className={"font-size-14 font-weight-500p text-white"}>Designed & developed by <a href={"https://7dotss.com/"} target={"_blank"} style={{textDecoration: 'none'}} className={"font-color-primary-yellow"}>7 Dots</a></label>
                            </div>
                            {/*<div className={"col-md-6 col-8 bg-white-color footer-logo-container"}>*/}
                            {/*    <div className={"w-100 d-flex align-items-center justify-content-center gap-2"}>*/}
                            {/*        <label className={"font-size-14 font-weight-500p font-color-primary-yellow"}>Designed by:</label>*/}
                            {/*        <div>*/}
                            {/*            <img src={"/images/landing/7-dotss-logo.png"} alt={"7 dotss logo"}/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterPage
