import React, {useEffect, useState} from "react";

import NavbarPage from "../NabarPage/navbarPage";
import FooterPage from "../FooterPage/footerPage";
import {Link} from "react-router-dom";
import {TailSpin} from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faYoutube, faLinkedin, faTwitter} from "@fortawesome/free-brands-svg-icons";

const LandingPage = () => {

    const [loadingButton, setLoadingButton] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [navbarItemsColor, setNavbarItemsColor] = useState(false);

    const handleOnFormSubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('enquiry-form');
        const data = new FormData(form);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,
        })
            .then(() => {
                setLoadingButton(false);
                setShowMessage('success');
                form.reset();
            }).catch((error) => {
            setLoadingButton(false);
            setShowMessage('error');
            form.reset();
            console.log(error)
        })
    }

    const handleScroll = () => {
        if (window.scrollY >= 500) {
            setNavbarItemsColor(true);

        } else {
            setNavbarItemsColor(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <div className="container-fluid">
            {/*hero section*/}

            <div className={"row bg-white"}>
                <div className={"col-12"}>
                    <div className="row bg-hero-section-container">
                        <div className="col-12">
                            {/*nav bar*/}
                            <div
                                className={`row justify-content-center fixed-top ${navbarItemsColor ? 'other-page-nav-color' : ''} `}>
                                <NavbarPage/>
                            </div>

                            {/*hero section*/}
                            <div className="row justify-content-between">
                                <div
                                    className="col-md-8 col-12 my-2 py-5 d-flex flex-column align-items-center justify-content-center">
                                    <div className={"row mt-5"}>
                                        <div className={"col-12"}></div>
                                    </div>
                                    <div className={"row justify-content-center"}>
                                        <div
                                            className={"col-10 d-flex flex-column align-items-center align-items-md-start justify-content-center"}>
                                            <div className={"row mt-4"}>
                                                <div className={"col-12"}></div>
                                            </div>
                                            <h1 className={"font-size-92 text-center text-md-start text-white"}>Welcome
                                                to Nakshatra Biofuels</h1>
                                            <div className={"mt-5 pt-2 d-none d-md-block"}>
                                                <Link to={'/contact-us'}>
                                                    <button type={'button'}
                                                            className={"button-bg bg-btn-shadow button-contact-us font-weight-500p font-size-18 font-color-neutral-dark"}>Contact
                                                        us
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className={"pt-2 mt-4 d-block d-md-none w-100"}>
                                                <Link to={'/contact-us'}>
                                                    <button type={'button'}
                                                            className={"button-bg bg-btn-shadow w-100 button-contact-us font-weight-500p font-size-18 font-color-neutral-dark"}>Contact
                                                        us
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 d-md-block d-none p-0 justify-content-end">
                                    <div className={"row d-flex h-100 justify-content-end"}>
                                        <div
                                            className={"col-4 icons-container bg-side-nav-color d-flex flex-column justify-content-center align-items-center gap-5"}>
                                            <FontAwesomeIcon icon={faYoutube} size={'2x'} color={'white'}/>
                                            <FontAwesomeIcon icon={faFacebookSquare} size={'2x'} color={'white'}/>
                                            <FontAwesomeIcon icon={faTwitter} size={'2x'} color={'white'}/>
                                            <FontAwesomeIcon icon={faLinkedin} size={'2x'} color={'white'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* about us*/}
                    <div className={"row"}>
                        <div className={"col-12 mt-md-5 py-3"}></div>
                    </div>

                    <div className={"row d-flex align-items-start"}>
                        <div className={"col-md-5 col-7 bg-dark-green d-flex justify-content-end align-items-center"}>
                            <div className={"bg-about-us-container"}>
                                <label className={"text-white font-size-56 font-weight-500p"}>About US</label>
                            </div>
                        </div>
                        <div className={"col-md-7 col-12 mt-md-5"}>
                            <div className={"row"}>
                                <div className={"col-2 d-block d-md-none"}></div>
                                <div
                                    className={"col-md-12 col-10 bg-color-light-green-color bg-about-content-container"}>
                                    <label className={"font-size-24 font-weight-400p font-color-neutral-dark"}>
                                        "Founded in 2021, Nakshatra Biofuels Pvt. Ltd. is at the forefront of the
                                        biofuels revolution in
                                        India. We specialize in producing high-quality biofuels and chemical products
                                        that contribute to
                                        a cleaner environment and a sustainable future. Our state-of-the-art distillery,
                                        located in the heart
                                        of Haryana, is expanding its capacity to meet the growing demand for renewable
                                        energy. At
                                        Nakshatra, we are committed to innovation, sustainability, and the responsible
                                        use of natural
                                        resources."
                                    </label>
                                    <div className={"mt-4 pt-3 d-none d-md-block"}>
                                        <button type={"button"}
                                                className={"button-bg bg-btn-shadow button-contact-us font-size-18 font-weight-500p font-color-neutral-dark"}>Know
                                            more
                                        </button>
                                    </div>
                                    <div className={"mt-4 pt-2 d-block d-md-none w-100"}>
                                        <button type={"button"}
                                                className={"button-bg bg-btn-shadow w-100 button-contact-us font-size-18 font-weight-500p font-color-neutral-dark"}>Know
                                            more
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* eco friendly */}
                    <div className={"row"}>
                        <div className={"col-12 mt-md-5 py-3"}></div>
                    </div>

                    <div className={"row justify-content-center"}>
                        <div className={"col-md-10 col-12 mt-4 pt-2 mt-md-0 pt-md-0"}>
                            <div className={"w-100 d-flex justify-content-center text-center"}>
                                <label className={"font-color-neutral-dark font-size-56 font-weight-500p"}>
                                    How We Make It <span className={"font-color-green"}>Eco Friendly</span>
                                </label>
                            </div>
                            <div className={"row"}>
                                <div className={"col-12 mt-md-5 pt-2 mt-4"}></div>
                            </div>
                            <div className={"row justify-content-center"}>
                                <div className={"col-md-12 col-11"}>
                                    <img className={"w-100 d-md-block d-none img-fluid bg-container-shadow rounded-5"}
                                         src={"/images/landing/eco-friendly-image.png"}
                                         alt={"eco-image"}/>
                                    <img className={"w-100 d-md-none d-block img-fluid bg-container-shadow rounded-5"}
                                         src={"/images/landing/mobile-eco-friendly-image.png"}
                                         alt={"eco-image"}/>
                                </div>
                            </div>
                            <div
                                className={"row d-flex mt-3 pt-3 justify-content-md-between justify-content-center align-items-center"}>
                                <div className={"col-md-9 col-11"}>
                                    <div>
                                        <label
                                            className={"font-color-neutral-grey-darker font-size-18 font-weight-400p"}>
                                            "At Nakshatra Biofuels, sustainability is at the core of our business. From
                                            our eco-friendly
                                            production processes to our commitment to reducing carbon emissions, we are
                                            dedicated to
                                            protecting the planet for future generations. Our plant in Karnal, Haryana,
                                            operates as a Zero
                                            Liquid Discharge (ZLD) facility, ensuring that no waste or wastewater is
                                            released into the
                                            environment. Our upcoming expansion project will further increase our
                                            capacity to produce
                                            sustainable energy, reinforcing our role as a key player in the green energy
                                            sector."
                                        </label>
                                    </div>
                                </div>
                                <div className={"col-md-3 mt-3 mt-md-0 col-12"}>
                                    <Link to={'/contact-us'}>
                                        <button type={"button"}
                                                className={"button-bg bg-btn-shadow w-100 button-contact-us font-size-18 font-weight-500p font-color-neutral-dark"}>Contact
                                            us
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 mt-md-5 py-3"}></div>
                    </div>

                    {/* contact us*/}
                    <div className={"row justify-content-between"}>
                        <div className={"col-md-11 col-12"}>
                            <div className={"row"}>
                                <div className={"col-12 mt-md-5 py-3"}></div>
                            </div>
                            {/* form and content */}
                            <div className={"row d-flex justify-content-md-between justify-content-center"}>
                                <div className={"col-md-6 col-12 d-flex flex-column"}>
                                    <div className={"row d-flex justify-content-center"}>
                                        <div className={"col-12 mb-md-5 mb-4 pb-2 text-center"}>
                                            <label
                                                className={"font-color-secondary-default font-weight-500p font-size-56"}>Contact
                                                us</label>
                                        </div>
                                    </div>
                                    <div
                                        className={"row py-md-5 py-2 bg-color-light-green-color mt-md-0 d-none d-md-flex flex-column align-items-center flex-grow-1"}>
                                        <div className={"col-12 pt-md-3"}></div>
                                        <div className={"col-md-5 col-11"}>
                                            <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Visiting
                                                hours</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>Monday
                                                    - Friday</label>
                                                <div>
                                                    <label
                                                        className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>10:00
                                                        am - 11:00 pm</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 my-3 py-md-4"}></div>
                                        <div className={"col-md-5 col-11"}>
                                            <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Our
                                                Address</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>B-183,
                                                    Gujranwala Town, New Delhi 110009</label>
                                            </div>
                                        </div>
                                        <div className={"col-12 my-3 py-md-4"}></div>
                                        <div className={"col-md-5 col-11"}>
                                            <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Get
                                                in touch</label>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>
                                                    +91-82854 50869
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-5 col-11 mt-md-0  rounded-4 bg-form-shadow p-md-4 p-3"}>
                                    <div>
                                        <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Get
                                            in Touch With Us</label>
                                        <div>
                                            <label
                                                className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>
                                                Interested in learning more about our products or discussing potential
                                                collaborations? We’d
                                                love to hear from you. Reach out to us at our corporate office in Delhi
                                                or visit our distillery in
                                                Haryana.
                                            </label>
                                        </div>
                                    </div>
                                    <form className={"d-flex flex-column mt-4 pt-3"} method={"POST"}
                                          onSubmit={handleOnFormSubmit}
                                          id={'enquiry-form'}
                                          action={'https://script.google.com/macros/s/AKfycbwKSHeANlNSBKvxEZSipVAJxk0z1fLTZklUIrFew-v-2G6W7-VEPj5vp_Etgoaca8yqPQ/exec'}>
                                        <div className={"w-100"}>
                                            <label className={"font-size-18 font-weight-500p font-color-neutral-dark"}
                                                   htmlFor={'fullName'}>
                                                Name</label>
                                            <input name={"FullName"} required={true} placeholder={"Enter your name"}
                                                   type={"text"} id={'fullName'}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={"phoneNumber"}
                                                   className={"font-size-18 font-weight-500p font-color-neutral-dark"}>
                                                Mobile Number</label>
                                            <input name={"PhoneNumber"} required={true} id={'phoneNumber'}
                                                   type={"number"} placeholder={"Enter your mobile number"}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'email'}
                                                   className={"font-size-18 font-weight-500p font-color-neutral-dark"}>
                                                E-mail id<span className={"font-color-grey-light"}>(optional)</span></label>
                                            <input name={"Email"} id={'email'} type={"email"}
                                                   placeholder={"Enter your email"}
                                                   className={"form-control"}/>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'selectedDepartment'}
                                                   className={"font-size-18 font-weight-500p font-color-neutral-dark"}>Select
                                                the department</label>
                                            <select name={"Department"} id={'selectedDepartment'} type={"select"}
                                                    className={"form-select"}>
                                                <option
                                                    className={"font-size-18 font-weight-500p font-color-neutral-dark"}
                                                    value={'Purchase'}>Purchase
                                                </option>
                                                <option
                                                    className={"font-size-18 font-weight-500p font-color-neutral-dark"}
                                                    value={'Sales'}>Sales
                                                </option>
                                                <option
                                                    className={"font-size-18 font-weight-500p font-color-neutral-dark"}
                                                    value={'Hr'}>Hr
                                                </option>
                                            </select>
                                        </div>
                                        <div className={"w-100 mt-4"}>
                                            <label htmlFor={'message'}
                                                   className={"font-size-18 font-weight-500p font-color-neutral-dark"}>
                                                Message</label>
                                            <textarea name={"Message"} id={'message'} rows={5}
                                                      placeholder={"Write your message"}
                                                      className={"form-control"}></textarea>
                                        </div>
                                        <div className={"mt-4 pt-3 d-md-flex d-none"}>
                                            <button type="submit" disabled={loadingButton}
                                                    className={"button-bg bg-btn-shadow button-contact-us font-color-neutral-dark font-size-18 font-weight-500p"}>
                                                {loadingButton ? <TailSpin width={20} height={20}
                                                                           color={'#1D1D1D'}/> : 'Send Message'}
                                            </button>
                                        </div>
                                        <div className={"mt-4 pt-3 d-md-none d-flex"}>
                                            <button type="submit" disabled={loadingButton}
                                                    className={"button-bg bg-btn-shadow button-contact-us w-100 font-color-neutral-dark font-size-18 font-weight-500p"}>
                                                {loadingButton ? <TailSpin width={20} height={20}
                                                                           color={'#1D1D1D'}/> : 'Send Message'}
                                            </button>
                                        </div>
                                        {showMessage === 'success' && (
                                            <div className={"mt-4 bg-light-green p-md-4 p-3 br-8px"}>
                                                <label
                                                    className={"font-color-dark-green font-size-18 font-weight-500p"}>Thank
                                                    you for contacting us! Your message has been successfully
                                                    received. We will get back to you shortly.</label>
                                            </div>)}
                                        {showMessage === 'error' && (
                                            <div className={"mt-4 bg-light-red p-md-4 p-3 br-8px"}>
                                                <label
                                                    className={"font-color-neutral-dark font-size-18 font-weight-500p"}>Oops!
                                                    Something went wrong while submitting your message. Please try
                                                    again later. Thank you for your understanding.</label>
                                            </div>)}
                                    </form>
                                </div>

                            </div>
                            <div
                                className={"row py-md-5 py-4 bg-color-light-green-color mt-4 mt-md-0 d-md-none d-flex flex-column align-items-center flex-grow-1"}>
                                <div className={"col-12 pt-md-3"}></div>
                                <div className={"col-md-5 col-11 p-0"}>
                                    <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Visiting
                                        hours</label>
                                    <div>
                                        <label
                                            className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>Monday
                                            - Friday</label>
                                        <div>
                                            <label
                                                className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>10:00
                                                am - 11:00 pm</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12 my-3 py-md-4"}></div>
                                <div className={"col-md-5 col-11 p-0"}>
                                    <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Our
                                        Address</label>
                                    <div>
                                        <label
                                            className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>B-183,
                                            Gujranwala Town, New Delhi 110009</label>
                                    </div>
                                </div>
                                <div className={"col-12 my-3 py-md-4"}></div>
                                <div className={"col-md-5 col-11 p-0"}>
                                    <label className={"font-size-32 font-weight-500p font-color-neutral-dark"}>Get
                                        in touch</label>
                                    <div>
                                        <label
                                            className={"font-size-18 font-weight-400p font-color-neutral-grey-darker"}>
                                            +91-82854 50869
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 mt-md-5 py-md-3"}></div>
                            </div>
                        </div>
                    </div>

                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default LandingPage
