import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link, useLocation} from "react-router-dom";
import productsData from '../Models/eachProduct.json'
import Offcanvas from 'react-bootstrap/Offcanvas';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faLinkedin, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";

const NavbarPage = () => {

    const [showDropDown, setShowDropDown] = useState(false);
    const [navItemsColor, setNavItemsColor] = useState('font-color-secondary-default');
    const [navBgColor, setNavBgColor] = useState('bg-color-navbar');
    const location = useLocation();

    const handleScroll = () => {
        if (window.scrollY >= 500) {
            setNavItemsColor('font-color-secondary-default');
            setNavBgColor('bg-scroll-navbar-color');
        } else if (window.scrollY < 500 && location.pathname === '/') {
            setNavItemsColor('text-white');
            setNavBgColor('bg-color-navbar');
        } else {
            setNavItemsColor('font-color-secondary-default');

        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },);

    useEffect(() => {
        if (location.pathname === '/') {
            setNavItemsColor('text-white')
            setNavBgColor('bg-color-navbar');
        } else {
            setNavItemsColor('font-color-secondary-default')
            setNavBgColor('bg-scroll-navbar-color');
        }
    }, [location.pathname]);

    return (
        <div className={'col-12 p-0'}>
            <Navbar collapseOnSelect expand="md" className={`${navBgColor} d-none d-md-flex justify-content-center`}
                    fixed={"top"}>
                <Container className='row'>
                    <Navbar.Brand className="col-md-2 col-4 p-0">
                        <div className="row justify-content-start">
                            <div className={"col-6"}>
                                <Link to={"/"}>
                                    <img className={"img-fluid"} src={"/images/landing/nakshatra-logo-with-name.png"}
                                         alt={"logo"}/>
                                </Link>
                            </div>
                            {/*<div className={"col-10"}>*/}
                            {/*    <img className={"img-fluid"} src={"/images/landing/Nakshatra-logo-content.png"}*/}
                            {/*         alt={"logo"}/>*/}
                            {/*</div>*/}
                            {/*<div className={"col-10"}>*/}
                            {/*    <label className={`font-size-14 ${navItemsColor} text-wrap text-center`}>Nakshatra Biofuels Pvt.Ltd.</label>*/}
                            {/*</div>*/}
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className={"col-2 border-0"}/>

                    <Navbar.Collapse id="" className={"col-md-6 col-12"}>
                        <Nav className="d-flex gap-md-5 gap-2 align-items-md-center">
                            <NavDropdown title="Products"
                                         onMouseEnter={() => setShowDropDown(true)}
                                         onMouseLeave={() => setShowDropDown(false)}
                                         className={`font-size-18 font-weight-600p products-dropdown`}
                                         show={showDropDown}>
                                {productsData.map(eachProduct => (
                                    <NavDropdown.Item key={eachProduct.id} className={""}>
                                        <Link to={`/products/${eachProduct.product_slug}`}>
                                            <label
                                                className="font-size-18 font-weight-500p font-color-neutral-dark">{eachProduct.product_name}</label>
                                        </Link>
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            <Nav>
                                <Link to={"/"}>
                                    <button type={"button"}
                                            className={` px-0 border-0 bg-transparent ${navItemsColor} font-size-18 font-weight-600p border-none`}>
                                        CSR
                                    </button>
                                </Link>
                            </Nav>
                            <Nav>
                                <Link to={"/careers"}>
                                    <button
                                        className={` px-0 border-0 bg-transparent ${navItemsColor} font-size-18 font-weight-600p border-none`}>
                                        Career
                                    </button>
                                </Link>
                            </Nav>
                            <Nav>
                                <Link to={"/contact-us"}>
                                    <button
                                        className={` px-0 border-0 bg-transparent ${navItemsColor} font-size-18 font-weight-600p border-none`}>
                                        Contact us
                                    </button>
                                </Link>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>

                    <Navbar.Collapse className={"col-md-4"}>
                        <Nav className="d-flex align-items-md-center py-3 py-md-0 w-100 justify-content-md-end">
                            <Nav>
                                <Link to={"/contact-us"}>
                                    <button type="button"
                                            className="button-bg font-color-neutral-dark font-size-14 font-weight-600p border-none">
                                        Contact us
                                    </button>
                                </Link>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {['sm'].map((expand) => (
                <Navbar key={expand} expand={expand}
                        className={`${navBgColor} d-flex d-md-none justify-content-center`} fixed={"top"}>
                    <Container fluid className={"row"}>
                        <Navbar.Brand className="col-md-2 d-flex justify-content-start col-6 p-0">
                            <div className="row justify-content-start align-items-center">
                                <div className={"col-10 d-flex justify-content-center align-items-center"}>
                                    <Link to={"/"}>
                                        <img className={"img-fluid"}
                                             src={"/images/landing/nakshatra-bio-fuels-logo.png"}
                                             alt={"logo"}/>
                                    </Link>
                                    <div className={"ps-1 d-flex flex-column justify-content-center"}>
                                        <label className={`font-size-12 ${navItemsColor} font-weight-500p text-wrap text-left`}>Nakshatra Biofuels Pvt.Ltd.</label>
                                    </div>
                                </div>
                                {/*<div className={"col-12"}>*/}
                                {/*    <img className={"img-fluid"} src={"/images/landing/Nakshatra-logo-content.png"}*/}
                                {/*         alt={"logo"}/>*/}
                                {/*    /!*<label className={`font-size-14 ${navItemsColor} font-weight-500p text-wrap text-center`}>Nakshatra Biofuels Pvt.Ltd.</label>*!/*/}
                                {/*</div>*/}
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className={"col-2 border-0"}/>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className={"col-6"}>
                                    <div className="row justify-content-start">
                                        <div className={"col-10 d-flex justify-content-center align-items-center"}>
                                            <Link to={"/"}>
                                                <img className={"img-fluid"}
                                                     src={"/images/landing/nakshatra-bio-fuels-logo.png"}
                                                     alt={"logo"}/>
                                            </Link>
                                            <div className={"ps-1 d-flex flex-column justify-content-center"}>
                                                <label className={`font-size-12 font-color-secondary-default font-weight-500p text-wrap text-left`}>Nakshatra Biofuels Pvt.Ltd.</label>
                                            </div>
                                        </div>
                                        {/*<div className={"col-10"}>*/}
                                        {/*    <img className={"img-fluid"} src={"/images/landing/Nakshatra-logo-content.png"}*/}
                                        {/*         alt={"logo"}/>*/}
                                        {/*    /!*<label className={`font-size-14 font-color-neutral-dark font-weight-500p text-wrap text-center`}>Nakshatra Bio*!/*/}
                                        {/*    /!*    Fuels Pvt.Ltd.</label>*!/*/}
                                        {/*</div>*/}
                                    </div>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className={"col-12"}>
                                <Nav className="d-flex flex-column gap-4 w-100">
                                    <NavDropdown title="Products"
                                                 onMouseEnter={() => setShowDropDown(true)}
                                                 onMouseLeave={() => setShowDropDown(false)}
                                                 className={`font-size-18 font-weight-600p products-dropdown border-0`}
                                                 show={showDropDown}>
                                        {productsData.map(eachProduct => (
                                            <NavDropdown.Item
                                                key={eachProduct.id}>
                                                <Link to={`/products/${eachProduct.product_slug}`}>
                                                    <label
                                                        className="font-size-18 font-weight-500p font-color-neutral-dark">{eachProduct.product_name}</label>
                                                </Link>
                                            </NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                    <Nav>
                                        <Link to={"/"}>
                                            <button type={"button"}
                                                    className={` px-0 border-0 bg-transparent font-color-secondary-default font-size-18 font-weight-600p border-none`}>
                                                CSR
                                            </button>
                                        </Link>
                                    </Nav>
                                    <Nav>
                                        <Link to={"/careers"}>
                                            <button
                                                className={` px-0 border-0 bg-transparent font-color-secondary-default font-size-18 font-weight-600p border-none`}>
                                                Career
                                            </button>
                                        </Link>
                                    </Nav>
                                    <Nav>
                                        <Link to={"/contact-us"}>
                                            <button
                                                className={'px-0 border-0 bg-transparent font-color-secondary-default font-size-18 font-weight-600p border-none'}>
                                                Contact us
                                            </button>
                                        </Link>
                                    </Nav>
                                    <Nav>
                                        <div className={"col-12 d-flex justify-content-start align-items-center gap-4"}>
                                            <FontAwesomeIcon icon={faYoutube} size={'2x'} color={'dark'}/>
                                            <FontAwesomeIcon icon={faFacebookSquare} size={'2x'} color={'dark'}/>
                                            <FontAwesomeIcon icon={faTwitter} size={'2x'} color={'dark'}/>
                                            <FontAwesomeIcon icon={faLinkedin} size={'2x'} color={'dark'}/>
                                        </div>
                                    </Nav>
                                    <Nav className="d-flex align-items-center align-self-center row fixed-bottom">
                                        <Nav className={'col-10 d-flex justify-content-center py-3'}>
                                            <Link to={"/contact-us"}>
                                                <button type="button"
                                                        className="button-bg font-color-neutral-dark w-100 font-size-18 font-weight-500p border-none">
                                                    Contact us
                                                </button>
                                            </Link>
                                        </Nav>
                                    </Nav>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </div>
    )
}

export default NavbarPage
